import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './manage_all_page.less'
import LeftSideHubCampaign from '../left_side_hub_campaign'
import smoothScrollPolyfill from 'smoothscroll-polyfill'
import PayPalPage from 'components/common/paypal_page'
import ReactDOM from 'react-dom'
import ModalAnimations from 'components/common/transitions/modal_animations'
import {
  Link,
  Button,
  HeadingText,
  SegmentedController,
  SegmentedControllerPanel,
  ArrowLeftIcon,
  LoadingSpinner,
} from '@paypalcorp/pp-react'
import 'node_modules/@paypalcorp/pp-vx/dist/components/text/text.css'
import ManageCrowdfunding from 'components/glimpses/edit_glimpse/manage_cf'
import EmptyHub from '../empty_hub'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import clsx from 'clsx'
import ZeroFundraiserOrSkeleton from '../zero_fundraiser_or_skeleton'
import { Message } from '@paypalcorp/worldready-react'

class ManageAllPage extends React.Component {
  static propTypes = {
    campaigns: PropTypes.array.isRequired,
    glimpse: PropTypes.bool,
    isLoadingDNWTransaction: PropTypes.bool,
    fetchTransaction: PropTypes.func,
    fetchActivities: PropTypes.func,
    reloadTransactions: PropTypes.bool,
    fetchOrganizerUpdateList: PropTypes.func,
    isOrganizerUpdatesEnabled: PropTypes.bool,
    setCurrentCampaignId: PropTypes.func,
  }

  constructor(props) {
    super(props)
    const campaignId = _get(this.props, 'campaignId', '')
    let selectedCard = -1
    let selectedTabIdx =
      this.props.campaigns.openFundraisers.length > 0 ? '0' : '1'
    if (campaignId) {
      selectedCard = this.props.campaigns.openFundraisers.findIndex(
        campaign => campaign.id === campaignId
      )
      selectedTabIdx = '0'
      if (selectedCard === -1) {
        selectedCard = this.props.campaigns.closedFundraisers.findIndex(
          campaign => campaign.id === campaignId
        )
        selectedTabIdx = '1'
      }
    }

    if (selectedCard === -1) {
      selectedCard = 0
    }

    this.state = {
      selectedTabIdx,
      isOpen: false,
      openFundraisers: _get(this.props, 'campaigns.openFundraisers', {}),
      closedFundraisers: _get(this.props, 'campaigns.closedFundraisers', {}),
      defaultSelectedCampaigns:
        _get(this.props, `campaigns.openFundraisers[${selectedCard}]`) ||
        _get(this.props, `campaigns.closedFundraisers[${selectedCard}]`, {}),
      selectedCard,
    }
    this.modalRoot = document.getElementById('modal-root')
  }

  componentDidMount() {
    smoothScrollPolyfill.polyfill()
    this.managePageCallback(this.state.selectedCard)
  }

  componentDidUpdate(prevProps) {
    const previousCapaign = JSON.stringify(prevProps.campaigns.openFundraisers)
    const currentCampaign = JSON.stringify(this.props.campaigns.openFundraisers)
    if (previousCapaign !== currentCampaign) {
      this.onUpdate()
    }
  }

  onUpdate = () => {
    this.setState({
      openFundraisers: _get(this.props, 'campaigns.openFundraisers', {}),
      closedFundraisers: _get(this.props, 'campaigns.closedFundraisers', {}),
    })
  }

  tabHandler(e) {
    const segmentIndex = e.currentTarget.dataset.tabIndex
    const defaultSelectedCampaigns =
      segmentIndex === '0'
        ? _get(this.props, 'campaigns.openFundraisers[0]')
        : _get(this.props, 'campaigns.closedFundraisers[0]')
    this.props.setCurrentCampaignId(defaultSelectedCampaigns?.id)
    this.setState({
      selectedCard: 0,
      selectedTabIdx: segmentIndex,
      defaultSelectedCampaigns,
    })
    this.fetchTransactions(defaultSelectedCampaigns, true)
  }

  managePageCallback = (index, reloadTransactions) => {
    const defaultSelectedCampaigns =
      this.state.selectedTabIdx === '0'
        ? this.state.openFundraisers[index]
        : this.state.closedFundraisers[index]
    this.props.setCurrentCampaignId(defaultSelectedCampaigns?.id)
    this.setState({
      defaultSelectedCampaigns,
      selectedCard: index,
      isOpen: true,
    })

    this.fetchTransactions(defaultSelectedCampaigns, reloadTransactions)
  }

  fetchTransactions = (defaultSelectedCampaigns, reloadTransactions) => {
    reloadTransactions = reloadTransactions || this.props.reloadTransactions
    if (_isEmpty(defaultSelectedCampaigns) || !reloadTransactions) {
      return false
    }
    this.setState({ loadingActivities: true })
    const transactionsCall = [
      this.props.fetchTransaction(defaultSelectedCampaigns.id),
    ]
    if (this.props.isOrganizerUpdatesEnabled) {
      transactionsCall.push(
        this.props.fetchOrganizerUpdateList(defaultSelectedCampaigns.id)
      )
    }
    //We display activities only for GN campaign not for GNC
    if (defaultSelectedCampaigns && !defaultSelectedCampaigns.charity) {
      transactionsCall.push(
        this.props.fetchActivities(defaultSelectedCampaigns.id)
      )
    }
    Promise.all(transactionsCall)
      .then(() => {
        this.setState({ loadingActivities: false })
      })
      .catch(() => {
        this.setState({ loadingActivities: false })
      })
  }

  pageSpinner = () => {
    return (
      <div className={styles.pageLoading}>
        <LoadingSpinner screenReaderText="loading" size="xl" />
      </div>
    )
  }
  handleBack = () => {
    this.setState({ isOpen: false })
  }
  render() {
    const { isLoadingDNWTransaction } = this.props
    const campaignId = _get(this.state, 'defaultSelectedCampaigns.id')

    return (
      <PayPalPage>
        <div styleName="container">
          <div styleName="container__top">
            <HeadingText size="lg" styleName="top__heading-text">
              <Message id="components.pages.hub.manage_all_page.fundraiser_title" />
            </HeadingText>
            {(this.state.selectedTabIdx === '1' ||
              this.state.openFundraisers.length > 0) && (
              <Button
                as="a"
                secondary
                styleName="hidden-md"
                data-testid="start_fundraiser"
                href={`${GLOBAL_CONSTS.REQUEST_URI}/c/create`}
              >
                <Message id="components.pages.hub.manage_all_page.start_fundraiser_button" />
              </Button>
            )}
          </div>

          <div styleName="container__body">
            <div styleName="body__content">
              <div styleName="content__sidebar">
                <SegmentedController
                  name="TabLayout"
                  selectedTabIdx={this.state.selectedTabIdx}
                  tabs={[
                    {
                      label: (
                        <Message id="components.pages.hub.manage_all_page.open_title" />
                      ),
                      id: 'open-tab',
                      panelId: 'open-tab',
                    },
                    {
                      label: (
                        <Message id="components.pages.hub.manage_all_page.closed_title" />
                      ),
                      id: 'closed-tab',
                      panelId: 'closed-tab',
                    },
                  ]}
                  onClick={this.tabHandler.bind(this)}
                >
                  <SegmentedControllerPanel
                    id="about-tab-panel"
                    tabId="group1-tab1"
                  >
                    {this.state.openFundraisers.length > 0 ? (
                      <div styleName="sidebar__cards">
                        {this.state.openFundraisers.map((campaign, index) => (
                          <LeftSideHubCampaign
                            campaign={campaign}
                            key={campaign.id}
                            index={index}
                            leftSideHubCallback={() =>
                              this.managePageCallback(index, true)
                            }
                            selectedCard={this.state.selectedCard}
                          />
                        ))}

                        <Link
                          styleName="cards__start-fundraiser-btn"
                          href={`${GLOBAL_CONSTS.REQUEST_URI}/c/create`}
                        >
                          <Message id="components.pages.hub.manage_all_page.start_fundraiser_button" />
                        </Link>
                      </div>
                    ) : (
                      <EmptyHub title="open" />
                    )}
                  </SegmentedControllerPanel>
                  <SegmentedControllerPanel
                    id="contributors-tab-panel"
                    tabId="group1-tab2"
                  >
                    {this.state.closedFundraisers.length > 0 ? (
                      <div styleName="sidebar__cards">
                        {this.state.closedFundraisers.map((campaign, index) => (
                          <LeftSideHubCampaign
                            campaign={campaign}
                            key={campaign.id}
                            index={index}
                            leftSideHubCallback={() =>
                              this.managePageCallback(index, true)
                            }
                            selectedCard={this.state.selectedCard}
                          />
                        ))}
                      </div>
                    ) : (
                      <EmptyHub title="closed" />
                    )}
                  </SegmentedControllerPanel>
                </SegmentedController>
              </div>
              <div
                styleName={clsx('content__details', {
                  'content__details--slide-in': this.state.isOpen,
                })}
              >
                {isLoadingDNWTransaction && this.pageSpinner()}
                <>
                  <ArrowLeftIcon
                    styleName="visible-md"
                    onClick={this.handleBack}
                  />

                  {campaignId ? (
                    <ManageCrowdfunding
                      leftAlignFlag={true}
                      campaignId={campaignId}
                      loadingActivities={this.state.loadingActivities}
                    />
                  ) : (
                    <ZeroFundraiserOrSkeleton
                      selectedTabIdx={this.state.selectedTabIdx}
                    />
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
        {ReactDOM.createPortal(
          <ModalAnimations>{this.props.glimpse}</ModalAnimations>,
          this.modalRoot
        )}
      </PayPalPage>
    )
  }
}

export default cssModules(ManageAllPage, styles, { allowMultiple: true })
