export const SCRIPT_IDS = {
  STORE: 'store',
  MESSAGES: 'messages',
  NAVIGATION: 'navigation',
  WORLD_READY_MARKET_DATA: 'worldReadyMarketData',
}

export const ALLOWED_IMAGE_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/tiff',
]

export const UNALLOWED_ENCODED_IMAGE_TYPES = [
  '52494646', // Webp
  '57454250', // Webp
]
export const USER_AGREEMENT_LINK_COUNTRIES = ['FI', 'JP', 'LI']

export const DEFAULT_LOCALE = {
  LANGUAGE: 'en',
  COUNTRY: 'US',
}

export const TEST_OPTIONS_HEADER = 'X-PP-Test-Options'

export const AMOUNT_TYPES = {
  any: 'any',
  at_least: 'at_least',
  exactly: 'exactly',
}

export const GRADIENT_COLORS = {
  blue: 'blue',
  purple: 'purple',
  fuchsia: 'fuchsia',
  red: 'red',
  teal: 'teal',
  gray: 'gray',
  green: 'green',
  sky: 'sky',
  pink: 'pink',
  orange: 'orange',
}

export const EDITABLE_FIELDS = [
  'title',
  'end_date',
  'description',
  'category',
  'goal',
  'gradient_color',
  'background_image',
  'background_image_filename',
  'background_pan',
  'amount_type',
  'share_contributions',
  'share_contributors',
  'share_progress',
  'amount',
  'pledge',
]

export const DEFAULT_GRADIENT_COLOR = 'teal'

export const DAYS_THRESHOLD = 28

export const COVER_IMAGE_MAX_TOTAL_SIZE = 1600
export const CROWDFUNDING_COVER_IMAGE_MAX_TOTAL_SIZE = 5000000

export const CROWDFUNDING_GOAL_AMOUNT = {
  EUR: {
    MAX_AMOUNT: 20000,
    MIN_AMOUNT: 1,
  },
  AUD: {
    MAX_AMOUNT: 25000,
    MIN_AMOUNT: 1,
  },
  CAD: {
    MAX_AMOUNT: 25000,
    MIN_AMOUNT: 1,
  },
  GBP: {
    MAX_AMOUNT: 20000,
    MIN_AMOUNT: 1,
  },
  NOK: {
    MAX_AMOUNT: 140000,
    MIN_AMOUNT: 10,
  },
  DKK: {
    MAX_AMOUNT: 140000,
    MIN_AMOUNT: 10,
  },
  USD: {
    MAX_AMOUNT: 20000,
    MIN_AMOUNT: 1,
  },
  PLN: {
    MAX_AMOUNT: 50000,
    MIN_AMOUNT: 2,
  },
  SEK: {
    MAX_AMOUNT: 210000,
    MIN_AMOUNT: 10,
  },
}

export const CROWDFUNDING_CAMPAIGN_MAX_DAYS = 30
export const MONEY_POOLS_MAX_DAYS = CROWDFUNDING_CAMPAIGN_MAX_DAYS

export const COVER_IMAGE_UPLOAD_ERRORS = {
  FILE_INVALID_TYPE: 'file-invalid-type',
  FILE_TOO_LARGE: 'file-too-large',
  FILE_TOO_SMALL: 'file-too-small',
  TOO_MANY_FILES: 'too-many-files',
}

export const CONTRIBUTIONS_PAGE_SIZE = {
  mobile: 5,
  desktop: 10,
}

export const MIN_CONTRIBUTOR_ROW_HEIGHT = '1.6em'

export const MIN_ACTIVITY_ROW_HEIGHT = '1.1em'

export const USR_TXNS_COOKIE_EXPIRY = 15 * 60 * 1000

export const UNDER_REVIEW_ERROR = 'UNDER_REVIEW'
export const FOU_LIMIT_REACHED_ERROR = 'FOU_LIMIT_REACHED'

export const INVALID_IMAGE = 'INVALID_IMAGE'

export const IMAGE_SCAN_RESPONSES = {
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  MATCH: 'MATCH',
  NO_MATCH: 'NO_MATCH',
}

export const STORAGE_EXPIRATION = 1000 * 60 * 30 // 30 mins

export const FUNDING_SOURCES = {
  HOLDING: 'HOLDING',
  BANK_ACCOUNT: 'BANK_ACCOUNT',
}

export const CFS_INTENT = 'MONEYPOOLS'
export const CFS_ELIGIBILITY_URL = '/myaccount/bundle/product-eligibility'
export const BRAZIL_ELIGIBILITY_URL = '/policy/hub/cip'

export const TRANSACTION_DETAILS_URL = '/myaccount/transactions/details/'

export const CIP_FLOW_URL = {
  HOMEINFO_URL: '/myaccount/info/flow/educate',
  RETURN_URL: '/myaccount/summary',
  ENTRY_POINT: 'POOLS',
}

export const AUTH_STATES = {
  LOGGEDIN: 'LOGGEDIN',
  IDENTIFIED: 'IDENTIFIED',
}

export const ACCOUNT_TYPES = {
  PERSONAL: 'PERSONAL',
  PREMIER: 'PREMIER',
  BUSINESS: 'BUSINESS',
}

export const SUBSCRIPTION_TYPES = {
  WALLET: 'WALLET',
  CASH: 'CASH',
  CASH_PLUS: 'CASH_PLUS',
}
export const FEATURES_TYPES = {
  CROSS_BORDER: 'SEND_MONEY_CROSS_BORDER_FRIENDS_AND_FAMILY',
  DOMESTIC: 'SEND_MONEY_DOMESTIC_FRIENDS_AND_FAMILY',
}
export const FLOW_DETAILS = {
  FEATURE: 'PERSONAL',
  SUB_FLOW: 'CONTRIBUTE',
}

export const EXPERIMENTS = {
  MONEY_POOLS_CROWDFUNDING: {
    TREATMENT: 'Trmt_money_pools_crowdfunding_1',
    CONTROL: 'Ctrl_money_pools_crowdfunding',
  },
  AUCIP: {
    TREATMENT: 'PAYPALWALLET_FEATURES_RAMP_TRMT',
    CONTROL: 'PAYPALWALLET_FEATURES_RAMP_CTRL',
  },
  GENEROSITY_NETWORK_FOR_CHARITY: {
    TREATMENT: 'Trmt_Generosity_Network_For_Charity',
    CONTROL: 'Ctrl_Generosity_Network_For_Charity',
  },
  // poolsnodeweb & crowdfundingnodeweb elmo joint experiment
  // we are using this in both apps
  GENEROSITY_NETWORK_RAMP: {
    RESOURCE: 'main:generosity:network:ramp',
    CONTROL: 'Ctrl_generosity_network_ramp',
    TREATMENT: 'Trmt_generosity_network_ramp',
  },
  GENEROSITY_NETWORK_DISCOVERABLE_GN: {
    RESOURCE: 'main:generosity:network:discoverable',
    CONTROL: 'Ctrl_generosity_network_discoverable_gn_v2',
    TREATMENT: 'Trmt_generosity_network_discoverable_gn_v2',
  },
  GENEROSITY_NETWORK_DISCOVERABLE_GNC: {
    RESOURCE: 'main:generosity:network:discoverable',
    CONTROL: 'Ctrl_generosity_network_discoverable_gnc_v2',
    TREATMENT: 'Trmt_generosity_network_discoverable_gnc_v2',
  },
  GENEROSITY_NETWORK_DISCOVERABLE_TOOLTIP: {
    RESOURCE: 'main:generosity:network:discoverable',
    CONTROL: 'Ctrl_generosity_network_discoverable_tooltip_v2',
    TREATMENT: 'Trmt_generosity_network_discoverable_tooltip_v2',
  },
  PP_FUNDRAISER_PRICING_DISCLAIMER: {
    RESOURCE: 'PP_Fundraiser_Pricing_Disclaimer',
    CONTROL: 'Ctrl_PayPal_Fundraisers_Pricing_Disclaimer_Client',
    TREATMENT: 'Trmt_PayPal_Fundraisers_Pricing_Disclaimer_Client',
  },
  PP_ORGANIZER_UPDATES: {
    RESOURCE: 'main:fundraiser:organizer_updates',
    CONTROL: 'Ctrl_organizer_update_ramp_web',
    TREATMENT: 'Trmt_organizer_update_ramp_web',
  },
  UNENROLLED_CHARITY: {
    RESOURCE: 'main:fundraiserserv:giving_unenrolled_charity_fundraising',
    CONTROL: 'Ctrl_dw2_giving_unenrolled_charity_fundraising',
    TREATMENT: 'Trmt_dw2_giving_unenrolled_charity_fundraising',
  },
  PERSONAL_FUNDRAISER_TITLE_AND_MIN_DESCRIPTION: {
    RESOURCE: 'main:fundraiser::title_minimum_description_length',
    CONTROL:
      'Ctrl_dw2_giving_listed_fundraiser_title_info_text_min_description_length',
    TREATMENT:
      'Trmt_dw2_giving_listed_fundraiser_title_info_text_min_description_length',
  },
  PERSONAL_FUNDRAISER_SOCIAL_SHARE: {
    RESOURCE: 'main:fundraiser::social_share',
    CONTROL: 'Ctrl_dw2_giving_listed_fundraiser_social_share',
    TREATMENT: 'Trmt_dw2_giving_listed_fundraiser_lift_in_amount_raised',
    TREATMENT2:
      'Trmt_dw2_giving_listed_fundraiser_duration_amount_can_be_raised',
  },
}

export const ELMO_RESOURCE_NAME = [
  'main:walletweb:transfer',
  'main:paypalwallet:feature',
].join(',')
export const ELMO_CROWDFUNDING_RESOURCE_NAME = 'main:crowdfunding:pool'

export const ELMO_GN_IMAGE_SCAN = 'main:generosity:network:image_scan'

export const ELMO_GN_DNW_INTEGRATION = 'main:generosity:network:dnw_integration'

export const ELMO_GN_CHARITY = 'main:generosity:network:charity'

export const PXP_PAGE_NAME = 'cfpb_paypal_cash_us'

export const POOLS_CAMPAIGN_TYPES = {
  CROWD_FUNDING: 'CROWD_FUNDING',
}
export const CHARITY = 'charity'

export const CROWD_FUNDING_HOME_URI = '/generosity-network'
export const CIP_ELIGIBILITY_CODES = {
  USER_REACHED_CAMPAIGN_CREATION_LIMIT: 'USER_REACHED_CAMPAIGN_CREATION_LIMIT',
  RUGS_CHECK_HIT_WITH_DC_NEED_MORE_DATA: 'NEED_MORE_DATA',
  RUGS_CHECK_HIT_WITH_DC_MANUAL_REVIEW: 'MANUAL_REVIEW',
  RUGS_CHECK_HIT_WITH_DC_PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  RUGS_CHECK_HIT_WITH_DC_PENDING: 'PENDING',
  RUGS_CHECK_HIT_FAILED: 'RUGS_CHECK_HIT_FAILED',
  RUGS_CHECK_FAILED: 'RUGS_CHECK_FAILED',
}

export const GENEROSITY_NETWORK_FLOWS = {
  GENEROSITY_NETWORK_FUNDRAISER_CHARITY:
    'GENEROSITY_NETWORK_FUNDRAISER_CHARITY',
  GENEROSITY_NETWORK_FUNDRAISER: 'GENEROSITY_NETWORK_FUNDRAISER',
}

export const NO_DESCRIPTION_ERRORS = ['REJECTED']

export const DEXTER_SPECIFIC_ERRORS = [
  'REJECTED',
  'AUTO_VERIFICATION_FAILED',
  'NOT_APPLICABLE',
  'SERVICE_UNAVAILABLE',
  'INVALID_REQUEST',
]

export const CIP_ERROR_CODE = [
  'REJECTED',
  'SERVICE_UNAVAILABLE',
  'MANUAL_REVIEW',
  'AUTO_VERIFICATION_FAILED',
  'NOT_APPLICABLE',
]

export const ELIGIBILITY_STATUS = {
  INELIGIBLE: 'INELIGIBLE',
  ELIGIBLE: 'ELIGIBLE',
  UNKNOWN: 'UNKNOWN',
}

export const MIN_TRANSFER_AMOUNT = 0

export const VERIFICATION_FLOW = {
  RUGS: 'RUGS',
  DEXTER: 'DEXTER',
}

export const PRODUCT_TYPES = {
  GENEROSITY_NETWORK: 'GENEROSITY_NETWORK',
  SMALL_GROUP: 'SMALL_GROUP',
  GENEROSITY_NETWORK_CHARITIES: 'GENEROSITY_NETWORK_CHARITIES',
  PAYPAL_FUNDRAISER_LISTED_CAMPAIGN: 'PAYPAL_FUNDRAISER_LISTED_CAMPAIGN',
  PAYPAL_FUNDRAISER_UNLISTED_CAMPAIGN: 'PAYPAL_FUNDRAISER_UNLISTED_CAMPAIGN',
}

const DIRECT = 'DIRECT'
const PPGF = 'PPGF'

export const INTEGRATION_IDENTIFIER = {
  DIRECT,
  PPGF,
}
export const DIRECT_MODEL_ENROLLED = 'DIRECT_MODEL_ENROLLED'
export const PPGF_ENROLLED = 'PPGF_ENROLLED'
export const PPGF_UNENROLLED = 'PPGF_UNENROLLED'

export const CHARITY_TYPES = {
  DIRECT_MODEL_ENROLLED: DIRECT_MODEL_ENROLLED,
  PPGF_ENROLLED: PPGF_ENROLLED,
  PPGF_UNENROLLED: PPGF_UNENROLLED,
}

export const PPGF_ENROLLED_LINKS = Object.freeze({
  US:
    'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy#statement-3',
  CA:
    'https://www.paypal.com/ca/non-profit/paypal-giving-fund/policies/charity-certification',
  GB:
    'https://www.paypal.com/uk/webapps/mpp/givingfund/policies#DonationDeliveryPolicy',
  IE:
    'https://www.paypal.com/ie/webapps/mpp/givingfund/donation-delivery-policy#Unenrolled%20Charities',
  AU:
    'https://www.paypal.com/au/webapps/mpp/givingfund/policies/donation-delivery',
})

export const PAYMENTS_TOKEN_DEFAULT_AMOUNT = '0'

export const INTEGRATION_IDENTIFIER_BY_COUNTRY = {
  AT: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  AU: {
    INTEGRATION_IDENTIFIER: PPGF,
  },
  BE: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  CA: {
    INTEGRATION_IDENTIFIER: PPGF,
  },
  DE: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  DK: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  ES: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  FR: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  GB: {
    INTEGRATION_IDENTIFIER: PPGF,
  },
  GR: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  IE: {
    INTEGRATION_IDENTIFIER: PPGF,
  },
  IT: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  LU: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  NL: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  NO: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  PL: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  PT: {
    INTEGRATION_IDENTIFIER: DIRECT,
  },
  US: {
    INTEGRATION_IDENTIFIER: PPGF,
  },
}

export const COUNTRIES_SMALL_LEGAL_TEXT_GNC = ['US', 'GB', 'AU', 'CA']
export const TNC_COUNTRIES = [
  'GB',
  'DE',
  'CA',
  'IT',
  'ES',
  'PL',
  'NL',
  'AT',
  'BE',
  'DK',
  'NO',
  'GR',
  'LU',
  'SE',
]
export const GN_DISCLAIMERS = {
  AT: {
    consumerLink: 'https://www.paypal.com/al/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/al/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/at/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/at/webapps/mpp/ua/useragreement-full',
  },
  AU: {
    consumerLink: 'https://www.paypal.com/au/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/au/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/au/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/au/webapps/mpp/ua/useragreement-full',
    donationDeliveryPolicy:
      'https://www.paypal.com/au/webapps/mpp/givingfund/policies/donation-delivery',
  },
  BE: {
    consumerLink: 'https://www.paypal.com/be/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/be/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/be/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/be/webapps/mpp/ua/useragreement-full',
  },
  CA: {
    consumerLink: 'https://www.paypal.com/ca/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/ca/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/ca/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/ca/webapps/mpp/ua/useragreement-full',
  },
  DE: {
    consumerLink: 'https://www.paypal.com/de/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/de/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/de/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/de/webapps/mpp/ua/useragreement-full',
  },
  DK: {
    consumerLink: 'https://www.paypal.com/dk/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/dk/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/DK/webapps/mpp/ua/generositynetwork-tnc',
    userAgreement:
      'https://www.paypal.com/dk/webapps/mpp/ua/useragreement-full',
  },
  ES: {
    consumerLink: 'https://www.paypal.com/es/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/es/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/es/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/es/webapps/mpp/ua/useragreement-full',
  },
  GB: {
    consumerLink: 'https://www.paypal.com/uk/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/uk/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/uk/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/uk/webapps/mpp/ua/useragreement-full',
  },
  GR: {
    consumerLink: 'https://www.paypal.com/gr/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/gr/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/gr/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/gr/webapps/mpp/ua/useragreement-full',
  },
  IT: {
    consumerLink: 'https://www.paypal.com/it/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/it/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/it/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/it/webapps/mpp/ua/useragreement-full',
  },
  LU: {
    consumerLink: 'https://www.paypal.com/lu/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/lu/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/lu/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/lu/webapps/mpp/ua/useragreement-full',
  },
  NL: {
    consumerLink: 'https://www.paypal.com/nl/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/nl/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/nl/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/nl/webapps/mpp/ua/useragreement-full',
  },
  NO: {
    consumerLink: 'https://www.paypal.com/no/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/no/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/no/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/no/webapps/mpp/ua/useragreement-full',
  },
  PL: {
    consumerLink: 'https://www.paypal.com/pl/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/pl/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/pl/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/pl/webapps/mpp/ua/useragreement-full',
  },
  US: {
    consumerLink: 'https://www.paypal.com/us/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/us/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/us/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/us/webapps/mpp/ua/useragreement-full',
  },
  SE: {
    consumerLink: 'https://www.paypal.com/se/webapps/mpp/paypal-fees',
    merchantLink: 'https://www.paypal.com/se/webapps/mpp/merchant-fees',
    termsAndConditions:
      'https://www.paypal.com/se/webapps/mpp/ua/pp-fundraisers-tnc',
    userAgreement:
      'https://www.paypal.com/se/webapps/mpp/ua/useragreement-full',
  },
}

export const COUNTRY_NOT_SUPPORTED = 'country_not_supported'

export const CROWD_FUNDING = 'CROWD_FUNDING'

export const GNC_DISCLAIMERS = {
  AU: {
    reassigningConditionsUrl:
      'https://www.paypal.com/au/webapps/mpp/givingfund/policies/donation-delivery',
    donationDeliveryPolicy:
      'https://www.paypal.com/au/webapps/mpp/givingfund/policies/donation-delivery',
  },
  CA: {
    reassigningConditionsUrl:
      'https://www.paypal.com/ca/webapps/mpp/givingfund/policies/donation-delivery-policy',
    donationDeliveryPolicy:
      'https://www.paypal.com/ca/webapps/mpp/givingfund/policies/donation-delivery-policy',
  },
  GB: {
    reassigningConditionsUrl:
      'https://www.paypal.com/uk/webapps/mpp/givingfund/policies#DonationDeliveryPolicy',
    donationDeliveryPolicy:
      'https://www.paypal.com/uk/webapps/mpp/givingfund/policies#DonationDeliveryPolicy',
  },
  US: {
    termsUrl:
      'https://www.paypal.com/webapps/mpp/givingfund/policies/donor-terms-of-service',
    failToDonateUrl:
      'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy',
    timeframeUrl:
      'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy',
    donationDeliveryPolicy:
      'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy',
  },
  IR: {
    donationDeliveryPolicy:
      'https://www.paypal.com/ie/webapps/mpp/givingfund/donation-delivery-policy',
  },
}

export const INSTRUMENT_EVENTS = {
  DW_GIVING: 'dw_giving',
  DW_GIVING_DETAIL_DONATE_PRESSED: 'dw_giving_detail_donate_pressed',
  DW_GIVING_MANAGE_FUNDRASIER_DONATE_PRESSED:
    'dw_giving_manage_fundraiser_donate_pressed',
}

export const INSTRUMENT_TAGS = {
  CL: 'cl',
  DONATE: 'donate',
  WEB: 'web',
}

export const KEY_PRESS_EVENTS = {
  ENTER_KEY: 'Enter',
}

export const TOAST_NOTIFICATIONS = {
  EDIT_SUCCESS: 'edit_success',
  COPY_SUCCESS: 'copy_success',
  DELETE_SUCCESS: 'delete_success',
}

export const SCREEN_NAME_MAPPING = {
  types: 'start_fundraiser',
  basics: 'create_fundraiser',
  story: 'tell_story',
  cover: 'add_photo',
  publish: 'publish',
}

export const ONE_DAY_IN_MILLISECONDS = 86400000

/**------PATHS------------- */
export const PATHS = {
  CREATE_CROWDFUNDING: 'create-crowdfunding',
}

export const CROWDFUNDING_TAB_PRESS = {
  ABOUT_TAB: 'about-tab',
  CONTRIBUTORS_TAB: 'contributors-tab',
}

export const GLOBAL_PPUI_THEME = 'v2'

export const DISCOVERABLE = 'DISCOVERABLE'
export const NON_DISCOVERABLE = 'NON_DISCOVERABLE'
export const ONLY_PEOPLE_WITH_LINK = 'only_people_with_link'
export const EVERYONE = 'everyone'

export const POOLS_STATUS = {
  EXPIRE_SOON: 'expire_soon',
  EXPIRED: 'expired',
  OPEN: 'open',
}
export const DEAFULT_COVER_IMAGES = [
  'https://www.paypalobjects.com/paypal-ui/illustrations/scenic/svg/blue-waves.svg',
  'https://www.paypalobjects.com/paypal-ui/illustrations/scenic/svg/green-circles.svg',
  'https://www.paypalobjects.com/paypal-ui/illustrations/scenic/svg/magenta-rings.svg',
  'https://www.paypalobjects.com/paypal-ui/illustrations/scenic/svg/orange-plaid.svg',
  'https://www.paypalobjects.com/paypal-ui/illustrations/scenic/svg/purple-mountain.svg',
  'https://www.paypalobjects.com/paypal-ui/illustrations/scenic/svg/yellow-stones.svg',
]

export const SOLID_BACKGROUND_IMAGE =
  'https://www.paypalobjects.com/paypal-ui/illustrations/scenic/svg/blue-waves.svg'

/**
 * For European Countries the session expiration time is 5 minutes
 * for an idle user, this is why we have 2 different sets of timers and resets
 */
const MINUTE = 1000 * 60
export const EXTEND_SESSION = {
  URL: '/pools/extendSession',

  SHOW_TIMEOUT_SCREEN_AT: 14 * MINUTE, // Show screen at the 14th minute of inactivity
  SHOW_EU_TIMEOUT_SCREEN_AT: 4 * MINUTE, // Show screen at the 4th minute of inactivity

  RESET_TIMER_TIMEOUT: 3 * MINUTE, // Only do an automatic extendSession call once every 3 minutes

  SESSION_TIMEOUT: 1 * MINUTE, // How much time after showing the screen should I close the session
}
export const CONTRIBUTION_TRANSACTIONS_PER_PAGE = 10
export const ACTIVITIES_TRANSACTIONS_PER_PAGE = 5

/**
 * Type of pools available
 *
 * PRIVATE: Unlisted(Non-Discoverable) pools
 * PERSONAL: Listed(Discoverable) pools
 * CHARITY: Listed(Discoverable) pools
 *
 * TODO: DTRAINIER-5301 Consolidate the use of GN|GNC|SGF terminology
 */
export const POOL_TYPE = {
  PRIVATE: 'small',
  PERSONAL: 'gn',
  CHARITY: 'gnc',
}

export const SPRIG_SURVEY = {
  [POOL_TYPE.PRIVATE]:
    'poolsnodeweb-gnFundraiserCreation-feedbacklink-web-dec2023',
  [POOL_TYPE.PERSONAL]:
    'poolsnodeweb-gnFundraiserCreation-feedbacklink-web-dec2023',
  [POOL_TYPE.CHARITY]:
    'poolsnodeweb-gncFundraiserCreation-feedbacklink-web-dec2023',
}

export const USER_CONSENT_STATUS = Object.freeze({
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
})

export const CHARITY_INSTRUMENTATION = Object.freeze({
  NPO_LINK_PRESSED: 'dw_giving_view_charity_fundraiser_npo_link_pressed',
  CHARITY_LINK_PRESSED:
    'dw_giving_view_charity_fundraiser_charity_link_pressed',
  READMORE_LINK_PRESSED:
    'dw_giving_view_charity_fundraiser_readmore_link_pressed',
  SHOWLESS_LINK_PRESSED:
    'dw_giving_view_charity_fundraiser_showless_link_pressed',
  PPGF_TNC_LINK_PRESSED:
    'dw_giving_view_charity_fundraiser_ppgf_tnc_link_pressed',
  FUNDRAISER_SCREEN_SHOWN: 'dw_giving_view_fundraiser_screen_shown',
})

export const COLLAPSIBLE_STATE = Object.freeze({
  COLLAPSED: 'collapsed',
  UNCOLLAPSED: 'uncollapsed',
})

export const AUDIENCE = Object.freeze({
  [DISCOVERABLE]: EVERYONE,
  [NON_DISCOVERABLE]: ONLY_PEOPLE_WITH_LINK,
})
