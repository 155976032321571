import CreatePage from 'components/pages/create'
import PoolTypeSmallGroup from 'components/pages/create/create_type_small_group'
import CountryBlockedError from 'components/common/country_blocked'
import { POOLS_CAMPAIGN_TYPES } from 'shared/shared_consts'
import { NEW_CAMPAIGN_ID, create } from 'redux/modules/campaign'
import { updateQueryParams } from 'redux/modules/url'
import {
  getStepName,
  assertInitialStep,
  assignActionToRouterParams,
} from 'lib/route_utils'

const POOLS_INITIAL_STEP = 'basics'
const CROWDFUNDING_INITIAL_STEP = 'types'
const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

export default (store, history) => ({
  path: 'create',
  getComponent(nextState, callback) {
    if (store.getState().current_user.moneypools_blocked) {
      return callback(null, CountryBlockedError)
    }
    return callback(null, CreatePage)
  },
  onEnter(nextState, replace) {
    store.dispatch((d, getState) => {
      d(create(CROWD_FUNDING)) // creates the new campaign object.
      d(updateQueryParams(nextState.location)) // updates the state with query params
    })
    nextState.params.nextStep = getStepName('create', nextState.routes)
    if (nextState.params.nextStep !== 'share/:campaignId') {
      nextState.params.campaignId = NEW_CAMPAIGN_ID
      let INITIAL_STEP = POOLS_INITIAL_STEP

      // set initial step to 80/20 if user falls into the treatment unless is in the initial step
      if (
        store.getState().current_user.crowdfunding_enabled &&
        nextState.params.nextStep !== POOLS_INITIAL_STEP
      ) {
        INITIAL_STEP = CROWDFUNDING_INITIAL_STEP
      }

      assertInitialStep('create', INITIAL_STEP, nextState, replace)
    }
  },
  onChange(prevState, nextState) {
    assignActionToRouterParams(nextState)
    nextState.params.campaignId = NEW_CAMPAIGN_ID
    nextState.params.prevStep = getStepName('create', prevState.routes)
    nextState.params.nextStep = getStepName('create', nextState.routes)
  },
  childRoutes: [
    {
      path: 'types',
      component: PoolTypeSmallGroup,
    },
  ],
})
