import CountryBlocked from './country_blocked'
import { connect } from 'react-redux'

const mapStateToProps = (state, props) => {
  return {
    countryName: state.country.country_name,
    countryCode: state.country.country_code,
    money_pool_creation_blocked: state.current_user.money_pool_creation_blocked,
    crowdfunding_enabled: state.current_user.crowdfunding_enabled,
    paypalDomain: state.geo.paypalDomain,
  }
}
export default connect(mapStateToProps)(CountryBlocked)
