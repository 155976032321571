import React from 'react'
import PropTypes from 'prop-types'
import PayPalPage from 'components/common/paypal_page'
import { Container, Row, Col } from '@paypalcorp/pp-react'
import { CREATION_BLOCKS } from '../../create/create'
import CreateFundraiserError from '../../create_fundraiser_error/create_fundraiser_error_container'
import CreateTypeSmallGroup from 'components/pages/create/create_type_small_group/create_type_small_group'

class ZeroFundraiser extends React.Component {
  static propTypes = {
    isWide: PropTypes.bool,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    blockType: PropTypes.oneOf(Object.values(CREATION_BLOCKS)),
    onComponentMount: PropTypes.func,
  }

  static defaultProps = {
    isWide: false,
    showHeader: true,
    showFooter: true,
  }

  size = !this.props.isWide && {
    md: 8,
    lg: 5,
  }

  componentDidMount() {
    if (this.props.onComponentMount) {
      this.props.onComponentMount()
    }
  }

  render() {
    const { blockType, showHeader, showFooter } = this.props
    return (
      <PayPalPage
        useWhiteBg={true}
        showHeader={showHeader}
        showFooter={showFooter}
      >
        <Container>
          <Row className="justify-content-md-center" align="center">
            <Col {...this.size}>
              {blockType ? (
                <CreateFundraiserError blockType={blockType} />
              ) : (
                <CreateTypeSmallGroup {...this.props} />
              )}
            </Col>
          </Row>
        </Container>
      </PayPalPage>
    )
  }
}

export default ZeroFundraiser
